import React, { useEffect } from "react";
import CoursesListSeo from "@/SEO/CoursesList";
import CoursesPageView from "./CoursesPage.view";
import CoursesPageBuilder, {
  getPageBuilderProps,
} from "@/mvcComponents/CommonUI/Sections/CoursesPageBuilder.controller";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import CoursesPageManager from "@/Logic/ClientLogic/Services/PageServices/CoursesPageManager";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import { DefaultCourseData } from "@/utils/LMSUtils";

UserCourseService.getInstance();

const CoursesPageController = ({ pageData, customPageData }) => {
  UserCourseService.instance.setupCourse(
    DefaultCourseData.course,
    DefaultCourseData.courseFromStrapi
  );
  ClientAppManager.instance.setPageManager(
    new CoursesPageManager(pageData, customPageData)
  );
  const { user } = useUserActions();

  const { seo = {}, faqs = [] } = pageData || {};
  const { coursesList = [] } = customPageData || {};

  const components = [
    {
      id: "CoursesListSeo",
      comp: CoursesListSeo,
      props: {
        metaData: seo,
        data: { coursesList, faqs },
      },
    },
    {
      id: "PageBuilder",
      comp: CoursesPageBuilder,
      props: getPageBuilderProps(pageData),
    },
  ];

  useEffect(() => {
    if (user) {
      UserCourseService.instance?.setUpUserCourseController(user);
    }
  }, [user?.userDetails?.user_access?.type]);

  return <CoursesPageView components={components} />;
};

export default CoursesPageController;
