import React from "react";
import CoursesPageController from "@/mvcComponents/Pages/CoursesPage/CoursesPage.Controller";
let CoursesPageDataController = null;

const CoursesLandingPage = ({ pageData, customPageData }) => {
  return (
    <CoursesPageController
      pageData={pageData}
      customPageData={customPageData}
    />
  );
};

export const getStaticProps = async ({ params, locale, defaultLocale }) => {
  try {
    if (!CoursesPageDataController) {
      CoursesPageDataController = (
        await import(
          "@/Logic/ServerLogic/PageDataControllers/Courses/CoursesPageData.controller"
        )
      )?.default;
      CoursesPageDataController.initialize("/courses");
    }

    const coursesPageData = await CoursesPageDataController.getPageData(
      locale,
      defaultLocale
    );

    let {
      pageData = {},
      customPageData = {},
      alternateLanguageData = {},
    } = coursesPageData || {};

    const { languageAlternates = {}, canonicalUrl = "" } =
      alternateLanguageData || {};

    let isRedirection = CoursesPageDataController.checkRedirection(pageData);
    if (isRedirection) {
      return isRedirection;
    }

    return {
      props: {
        pageData: {
          ...pageData,
          seo: {
            ...pageData?.seo,
            url: canonicalUrl,
            languageAlternates,
          },
        },
        customPageData,
      },
      revalidate: 600,
    };
  } catch (err) {
    console.log("ERROR", err);
    return {
      props: {
        pageData: {},
        customPageData: {},
      },
    };
  }
};

export default CoursesLandingPage;
